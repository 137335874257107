import useCurrentWidth from "utils/useCurrentWidth";
import {
  BREAKPOINT_MEDIUM,
  BREAKPOINT_MEDIUM_LARGE,
  BREAKPOINT_LARGE,
} from "styles/media";

// We could consider using window.matchMedia(), but `utils/useCurrentWidth` is
// already adding event listeners, so let's build on top of that for now.

export const isSmallScreen = () => {
  const windowWidth = useCurrentWidth();
  return windowWidth < BREAKPOINT_MEDIUM;
};

export const isMediumScreen = () => {
  const windowWidth = useCurrentWidth();
  return windowWidth < BREAKPOINT_MEDIUM_LARGE;
};

export const isMediumLargeScreen = () => {
  const windowWidth = useCurrentWidth();
  return windowWidth < BREAKPOINT_LARGE;
};
