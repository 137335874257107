import React from "react";
import PropTypes from "prop-types";
import styled from "styled-components";
import { BREAKPOINT_MEDIUM, BREAKPOINT_LARGE } from "styles/media";

const Picture = styled.picture`
  ${(props) => !!props.$width && `width: ${props.$width};`}
`;

const PictureImage = styled.img`
  ${(props) =>
    props.inline &&
    `
        display: initial;
    `}

  ${(props) => props.height && `height: ${props.height};`}
  ${(props) => props.width && `width: ${props.width};`}

  object-fit: cover;
`;

const Image = ({
  src,
  small,
  medium,
  large,
  className,
  alt,
  srcSet,
  loading,
  sizes,
  width,
  height,
  inline,
  decorative,
  containerClassName = "",
  ...props
}) => {
  // Default to lazy loading if nothing is explictly set and we know the width and height (to avoid layout shift)
  if (loading === undefined && width && height) {
    loading = "lazy"; // eslint-disable-line no-param-reassign
  }

  return (
    <Picture className={containerClassName} $width={width}>
      {large && (
        <source
          srcSet={large}
          data-srcset={large}
          media={`(min-width: ${BREAKPOINT_LARGE}px)`}
        />
      )}
      {medium && (
        <source
          srcSet={medium}
          data-srcset={medium}
          media={`(min-width: ${BREAKPOINT_MEDIUM}px)`}
        />
      )}
      {small && <source srcSet={small} data-srcset={small} />}
      <PictureImage
        height={height}
        width={width}
        inline={inline}
        src={src}
        srcSet={srcSet}
        sizes={sizes}
        loading={loading}
        alt={decorative && !alt ? "" : alt}
        className={className}
        role={decorative ? "presentation" : null}
        {...props}
      />
    </Picture>
  );
};

Image.propTypes = {
  alt: PropTypes.string,
  src: PropTypes.string.isRequired,
  small: PropTypes.string,
  medium: PropTypes.string,
  large: PropTypes.string,
  srcSet: PropTypes.string,
  sizes: PropTypes.string,

  /**
   * The HTML loading="lazy|eager" attribute
   */
  loading: PropTypes.string,

  className: PropTypes.string,
  containerClassName: PropTypes.string,
  height: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
  width: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
  inline: PropTypes.bool,
  decorative: PropTypes.bool,
};

export default styled(Image)``;
