import { useState, useEffect } from "react";

// hook to get current width of screen on
// debounced resize listener
const getWidth = () => {
  if (typeof window !== "undefined") {
    return (
      window.innerWidth ||
      document.documentElement.clientWidth ||
      document.body.clientWidth
    );
  }
  return null;
};

export default function useCurrentWidth() {
  const [width, setWidth] = useState(getWidth());

  useEffect(() => {
    if (typeof window !== "undefined") {
      // timeoutId for debounce
      let timeoutId = null;
      const resizeListener = () => {
        clearTimeout(timeoutId);
        timeoutId = setTimeout(() => setWidth(getWidth()), 150);
      };

      window.addEventListener("resize", resizeListener);

      return () => {
        window.removeEventListener("resize", resizeListener);
      };
    }
    return false;
  }, []);

  return width;
}
