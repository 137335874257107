import React, { useState } from "react";
import PropTypes from "prop-types";
import styled from "styled-components";
import AlertIcon from "public/icons/alert.svg";
import DropDownIcon from "public/icons/dropdown.svg";
import fontSizes from "styles/fontSizes";
import {
  white00,
  black70,
  gray50,
  gray15,
  green60,
  red90,
  red50,
} from "styles/colors";

const Container = styled.div`
  position: relative;
  display: inline-block;
  ${({ $inactive }) =>
    $inactive &&
    `
      cursor: not-allowed;
    `}
`;

const SelectEl = ({
  id,
  children,
  onChange,
  onBlur,
  disabled,
  value,
  className,
}) => (
  <select
    id={id}
    onChange={onChange}
    onBlur={onBlur}
    disabled={disabled}
    value={value}
    className={className}
  >
    {children}
  </select>
);

SelectEl.propTypes = {
  id: PropTypes.string,
  children: PropTypes.node,
  onChange: PropTypes.func,
  onBlur: PropTypes.func,
  disabled: PropTypes.bool,
  value: PropTypes.string,
  className: PropTypes.string,
};

const Selector = styled(SelectEl)`
  ${fontSizes[1]}
  font-family: inherit;
  background-color: ${white00};
  padding: 11px 1rem;
  border: 1px solid ${gray15};
  border-radius: 5px;
  transition: 0.8s cubic-bezier(0.2, 0.8, 0.2, 1);
  color: ${black70};
  option {
    padding: 0;
  }
  option:disabled {
    color: ${gray15};
  }
  :hover {
    cursor: pointer;
    border: 1px solid ${gray50};
  }
  :focus {
    outline: none;
    border: 2px solid ${green60};
    box-shadow: 0px 0px 0px 2px rgb(178, 220, 255);
    ${(props) => (props.small ? "padding: 6px 11px" : "padding: 10px 15px")};
  }
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
  ${({ small }) =>
    small &&
    `
      ${fontSizes[0]}
      padding: 7px 0.75rem;
  `}
  ${({ selecting, small }) =>
    selecting &&
    `
      border: 2px solid ${green60};
      ${small ? "padding: 6px 11px" : "padding: 10px 15px"};
      :hover {
        border: 2px solid ${green60};
        ${small ? "padding: 6px 11px" : "padding: 10px 15px"};
      }
    `}
  ${({ disabled }) =>
    disabled &&
    `
      cursor: not-allowed;
      :hover {
        cursor: not-allowed;
        border: 1px solid ${gray15};
        ${(props) =>
          props.small ? "padding: 7px 12px" : "padding: 11px 16px"};
      }
    `}
  ${({ selecting, value, small }) =>
    !selecting &&
    value !== "" &&
    value !== undefined &&
    `
        border: 2px solid ${green60};
        ${small ? "padding: 6px 11px" : "padding: 10px 15px"};
        :hover {
          border: 2px solid ${green60};
          ${small ? "padding: 6px 11px" : "padding: 10px 15px"};
        }
      `}
  min-width: 128px;
  ${({ error, small }) =>
    error &&
    `
      border: 2px solid ${red50};
      ${small ? "padding: 6px 11px" : "padding: 10px 15px"};
      :hover {
        border: 2px solid ${red50};
        ${small ? "padding: 6px 11px" : "padding: 10px 15px"};
      }
  `}
  width: 100%;
`;

const Label = styled.label`
  ${fontSizes[1]}
  position: absolute;
  top: 0;
  left: 0;
  transform: translate(17px, 12px) scale(1);
  pointer-events: none;
  transition: 0.8s cubic-bezier(0.2, 0.8, 0.2, 1);
  background-color: ${white00};
  color: ${black70};

  ${({ small }) =>
    small &&
    `
      ${fontSizes[0]}
      transform: translate(15px, 8px) scale(1);
  `}
  ${({ $selecting, value, small }) =>
    ($selecting || value) &&
    `
        transform: translate(${small ? "8" : "6"}px, -9px);
        ${fontSizes[0]}
        padding: 0.125rem 0.125rem;
        color: ${green60};
      `}
  ${({ $inactive }) =>
    $inactive &&
    `
      color: ${gray50};
      pointer-events: all;
      cursor: not-allowed;
    `}
  ${({ error }) =>
    error &&
    `
      color: ${red90}
  `}
`;

const Icon = ({ className }) => <DropDownIcon className={className} />;

Icon.propTypes = {
  className: PropTypes.string,
};

const StyledIcon = styled(Icon)`
  position: absolute;
  right: 0.75rem;
  top: 12px;
  pointer-events: none;
  height: 1.25rem;
  fill: ${gray50};
  background-color: ${white00};
  ${({ small }) =>
    small &&
    `top: 7px;
    `};
`;

const ErrorText = styled.div`
  ${fontSizes[0]}
  color: ${red90};
  margin-top: 0.125rem;
`;

const ErrorIcon = styled(AlertIcon)`
  position: relative;
  top: 3px;
  margin-right: 2px;
`;

const Select = ({
  id,
  options,
  onChange,
  disabled,
  name,
  small,
  errorMessage,
  className,
  hideLabel,
  ...props
}) => {
  const [selecting, setSelecting] = useState(false);
  const defaultOption = options.find((option) => option.default);
  const defaultValue = defaultOption ? defaultOption.value : undefined;
  const [value, setValue] = useState(defaultValue);
  const blankOption = defaultOption
    ? []
    : [<option key="blank" aria-label="None" value="" />];
  const selectOptions = blankOption.concat(
    options.map((item) => {
      const { label, disabled: optionDisabled } = item;
      const optionValue = item.value;
      let placeholderProps = {};
      if (item.placeholder) {
        placeholderProps = {
          disabled: true,
          hidden: true,
        };
      }
      return (
        <option
          value={optionValue}
          key={optionValue}
          disabled={optionDisabled}
          {...placeholderProps}
        >
          {label}
        </option>
      );
    })
  );

  return (
    <Container $inactive={disabled} className={className} {...props}>
      {name && (
        <Label
          htmlFor={id}
          $selecting={selecting}
          value={value}
          $inactive={disabled}
          small={small}
          error={errorMessage}
          className={hideLabel ? "visually-hidden" : undefined}
        >
          {name}
        </Label>
      )}
      <StyledIcon small={small} />
      <Selector
        id={id}
        onChange={(e) => {
          const inputValue = e.target.value;
          setValue(inputValue);
          onChange(inputValue);
        }}
        onBlur={() => {
          setSelecting(false);
        }}
        value={value}
        selecting={selecting}
        disabled={disabled}
        small={small}
        error={errorMessage}
      >
        {selectOptions}
      </Selector>
      {errorMessage && (
        <ErrorText>
          <ErrorIcon height="14px" fill={red90} />
          {errorMessage}
        </ErrorText>
      )}
    </Container>
  );
};

Select.propTypes = {
  options: PropTypes.arrayOf(
    PropTypes.shape({
      value: PropTypes.string,
      label: PropTypes.string,
      default: PropTypes.bool,
    })
  ),
  id: PropTypes.string,
  onChange: PropTypes.func,
  disabled: PropTypes.bool,
  small: PropTypes.bool,
  errorMessage: PropTypes.string,
  name: PropTypes.string,
  className: PropTypes.string,
  hideLabel: PropTypes.bool,
};

export default Select;
