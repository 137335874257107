import React from "react";
import PropTypes from "prop-types";
import styled from "styled-components";
import media from "styles/media";
import spacing from "styles/spacing";
import Link from "next/link";
import { PARTNERS_SHAPE } from "constants/propTypes";

const Container = styled.div`
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  text-align: center;
  ${media.medium`
    max-width: 1440px;
    margin: auto;
  `}
`;

const CruiseLine = styled.div`
  flex-basis: 50%;
  ${media.medium`
    flex-basis: 20%;
  `}
  a {
    display: inline-block;
    margin-bottom: ${spacing[5]};
  }
`;

// We are changing the opacity to simulate fill changes assuming logos are black.
// This is a quick fix for GTS-1298 and should be improved upon.
// Opacity: 0.8 is black70 equivalent.
// Opacity: 0.6 is a gray50 equivalent.
const LogoLink = styled(Link)`
  width: 100%;
  img {
    height: 24px;
    width: auto;
    max-width: 100%;
    opacity: 0.6;
    transition: all 0.8s cubic-bezier(0.2, 0.8, 0.2, 1) 0s;
  }
  &:hover {
    img {
      opacity: 0.8;
    }
  }
`;

const CruiseLineLogos = ({ partners }) => {
  return (
    <Container>
      {partners.map((partner) => (
        <CruiseLine key={partner.name}>
          <LogoLink href={`/search?ship__cruiseline_id=${partner.id}`}>
            <img
              src={partner.svg_url}
              height="24px"
              alt={`${partner.name} logo`}
            />
          </LogoLink>
        </CruiseLine>
      ))}
    </Container>
  );
};

CruiseLineLogos.propTypes = {
  partners: PropTypes.arrayOf(PARTNERS_SHAPE),
};

export default CruiseLineLogos;
