/* Base */
import React from "react";
import PropTypes from "prop-types";
import styled from "styled-components";
import { useRouter } from "next/router";
/* Utilities */
import { isSmallScreen } from "utils/getScreenSize";
/* Styles */
import media, {
  BREAKPOINT_TO_MEDIUM,
  BREAKPOINT_MEDIUM,
  BREAKPOINT_TO_LARGE,
  BREAKPOINT_LARGE,
} from "styles/media";
import spacing from "styles/spacing";
import fontSizes from "styles/fontSizes";
import { black70, gray05, gray50, white00 } from "styles/colors";
import "react-multi-carousel/lib/styles.css";
/* Constants */
import {
  FILTER_PROPS_FROM_API,
  PROMOTIONAL_ITEM_SHAPE,
} from "constants/propTypes";
/* Components */
import Cookies from "universal-cookie";
import Carousel from "react-multi-carousel";
import Link from "next/link";
import Button from "components/Button";
import Image from "components/Image";
import HeroSearch from "./HeroSearch";

const HERO_HEIGHT_SMALL = "270px";
const HERO_IMGS = [
  {
    fb: "home-1-sm.jpg",
    sm: "home-1-sm.webp",
    md: "home-1-md.webp",
    lg: "home-1-lg.webp",
  },
  {
    fb: "home-2-sm.jpg",
    sm: "home-2-sm.webp",
    md: "home-2-md.webp",
    lg: "home-2-lg.webp",
  },
  {
    fb: "home-3-sm.jpg",
    sm: "home-3-sm.webp",
    md: "home-3-md.webp",
    lg: "home-3-lg.webp",
  },
  {
    fb: "home-4-sm.jpg",
    sm: "home-4-sm.webp",
    md: "home-4-md.webp",
    lg: "home-4-lg.webp",
  },
];

const HeroContainer = styled.div`
  width: 100%;
  position: relative;
  ${media.medium`
    box-shadow: unset;
    padding-top: ${spacing[5]};
  `}
`;

const MobileOnlyHeroText = styled.div`
  color: ${white00};
  ${fontSizes[6]};
  padding: 90px 0 0 ${spacing[4]};
  width: 75%;
  height: 80px;
  font-weight: 700;
  text-shadow: 1px 1px 3px rgba(0, 0, 0, 0.5);
  ${media.medium`
    display: none;
  `}
`;
const Background = styled.div`
  text-align: -webkit-center;
`;
const HeroContentContainer = styled.div`
  max-width: 1448px;
  text-align: left;
`;

const HeroContent = styled.div`
  box-sizing: border-box;
  display: block;
  padding: 10px ${spacing[4]};
  margin-top: 20px;
  min-width: 240px;
  width: 100%;
  height: 80px;
  background-color: rgba(255, 255, 255, 0.9);
  ${media.medium`
    box-shadow: 0 0 8px 0 rgba(51, 51, 51, 0.18);
    border-radius: 10px;
    max-width: 480px;
    width: 50%;
    min-width: 320px;
    height: 328px;
    margin: 0 ${spacing[5]} ${spacing[5]};
    padding: ${spacing[5]};
  `}
`;

const CarouselContent = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  height: 80px;
  ${media.medium`
    height: 280px;
  `}
  color: ${black70};
`;

const CarouselLogo = styled.img`
  display: none;
  ${media.medium`
    display: block;
    width: 98px;
    height: 32px;
  `}
`;

const CarouselTitle = styled.div`
  ${fontSizes[4]}
  &:hover {
    color: ${gray50};
    cursor: pointer;
  }
  ${media.medium`
    ${fontSizes[6]}
    &:hover {
      color: inherit;
      cursor: inherit;
    }
  `}
  ${media.mediumLarge`
    ${fontSizes[7]}
  `}
  font-weight: bold;
`;

const CarouselDescription = styled.div`
  ${fontSizes[0]}
  margin-bottom: ${spacing[4]};
  ${media.medium`
    ${fontSizes[3]}
    margin-top: ${spacing[3]};
    margin-bottom: ${spacing[3]};
  `}
  ${media.mediumLarge`
    margin-bottom: ${spacing[4]};
  `}
  ${media.large`
    margin-bottom: ${spacing[5]};
  `}
`;

const HeroImageBox = styled.div`
  position: absolute;
  width: 100%;
  height: ${HERO_HEIGHT_SMALL};
  top: 0;
  ${media.medium`
    height: 100%;
  `}
  left: 0;
  z-index: -1;
  background-color: ${gray05};
`;

const HeroImage = styled(Image)`
  object-fit: cover;
  object-position: 50% 25%;
  ${media.extraLarge`
    object-position: 50% 20%;
  `}
  position: relative;
  z-index: -1;
  height: 100%;
  ${media.medium`
    height: 92%;
  `}
  width: 100%;
`;

const breakpointConfig = {
  desktop: {
    breakpoint: { max: 3000, min: BREAKPOINT_LARGE },
    items: 1,
  },
  tablet: {
    breakpoint: { max: BREAKPOINT_TO_LARGE, min: BREAKPOINT_MEDIUM },
    items: 1,
  },
  mobile: {
    breakpoint: { max: BREAKPOINT_TO_MEDIUM, min: 0 },
    items: 1,
  },
};

const PromoItem = ({ logo, title, description, url, isSmall }) => {
  const router = useRouter();
  return (
    <CarouselContent>
      <CarouselLogo src={logo} />
      <CarouselTitle {...(isSmall && { onClick: () => router.push(url) })}>
        {title} {isSmall && " »"}
      </CarouselTitle>
      <CarouselDescription>{description}</CarouselDescription>
      {!isSmall && (
        <div>
          <Link href={url}>
            <Button small={isSmall}>View Cruises</Button>
          </Link>
        </div>
      )}
    </CarouselContent>
  );
};

const Hero = ({ heroImgIndex, promotionalItems, filters }) => {
  // This sets a new cookie value that is used in getInitialProps
  // when the page initially renders on the server in order to
  // cycle through images in HERO_IMGS.
  // GTS-1134 sets `nextHeroImgIndex = 3`... uncomment below to cycle images instead.
  // const nextHeroImgIndex = (parseInt(heroImgIndex, 10) + 1) % HERO_IMGS.length;
  const nextHeroImgIndex = 3;
  const cookies = new Cookies();
  cookies.set("heroImgIndex", nextHeroImgIndex);

  const isSmall = isSmallScreen();
  return (
    <HeroContainer>
      <MobileOnlyHeroText>Match With Your Perfect Cruise</MobileOnlyHeroText>
      <Background>
        <HeroContentContainer>
          <HeroContent>
            <Carousel
              arrows={false}
              autoPlay
              autoPlaySpeed={10000}
              containerClass="carousel"
              dotListClass=""
              focusOnSelect={false}
              infinite
              keyBoardControl
              responsive={breakpointConfig}
              showDots={!isSmall}
              ssr
            >
              {promotionalItems.map(({ logo_url, title, description, url }) => (
                <PromoItem
                  logo={
                    logo_url ||
                    "https://book.gotosea.com/site/GoToSea/GoToSea.svg"
                  }
                  key={url}
                  title={title}
                  description={description}
                  url={url}
                  isSmall={isSmall}
                />
              ))}
            </Carousel>
          </HeroContent>
        </HeroContentContainer>
      </Background>
      <HeroSearch filters={filters} />
      <HeroImageBox>
        <HeroImage
          width="2500"
          height="1333"
          large={`/heroes/${HERO_IMGS[heroImgIndex].lg}`}
          medium={`/heroes/${HERO_IMGS[heroImgIndex].md}`}
          small={`/heroes/${HERO_IMGS[heroImgIndex].sm}`}
          src={`/heroes/${HERO_IMGS[heroImgIndex].fb}`}
          loading="eager"
          fetchpriority="high"
        />
      </HeroImageBox>
    </HeroContainer>
  );
};

const PROMO_SHAPE = {
  logo: PropTypes.string,
  title: PropTypes.string,
  description: PropTypes.string,
  url: PropTypes.string,
  isSmall: PropTypes.bool,
};

PromoItem.propTypes = PROMO_SHAPE;

Hero.propTypes = {
  heroImgIndex: PropTypes.number,
  promotionalItems: PropTypes.arrayOf(PROMOTIONAL_ITEM_SHAPE).isRequired,
  filters: FILTER_PROPS_FROM_API,
};

export default Hero;
