/* Base */
import React, { useState, useEffect } from "react";
import styled from "styled-components";
import getConfig from "next/config";
import { useRouter } from "next/router";
/* Utilities */
import { trackSearchCruises } from "utils/tracking";
/* Styles */
import media from "styles/media";
import spacing from "styles/spacing";
import fontSizes from "styles/fontSizes";
import { white00 } from "styles/colors";
/* Constants */
import { FILTER_PROPS_FROM_API } from "constants/propTypes";
/* Components */
import Select from "components/formControls/Select";
import Button from "components/Button";

const Container = styled.div`
  display: flex;
  flex-direction: column;
  padding: 16px;
  ${media.medium`
    flex-direction: row;
    align-items: center;
    justify-content: center;
    padding: 0 3%;
  `}
`;

const SearchContainer = styled.form`
  position: relative;
  display: flex;
  flex-wrap: wrap;
  width: 100%;
  max-width: 1045px;
  gap: ${spacing[4]};
  background-color: ${white00};
  ${media.medium`
    flex-wrap: nowrap;
    flex-direction: row;
    gap: ${spacing[3]};
    padding: ${spacing[4]};
    border-radius: 10px;
    border-right: none;
    box-shadow: 0 8px 10px 0 rgba(51,51,51,0.1);
  `};
  ${media.large`
    gap: ${spacing[4]};
  `};
`;

const SearchSelect = styled(Select)`
  width: 100%;
  margin-bottom: 0;

  select {
    box-sizing: border-box;
    width: 100%;
    min-width: auto;
  }
  ${media.medium`
    max-width: 210px;
  `};
`;

const FlexContainer = styled.div`
  display: ${(props) => props.display};
  justify-content: space-between;
  width: 100%;
  gap: ${spacing[4]};
  ${media.medium`
    display: flex;
    max-width: fit-content;
    gap: ${spacing[3]};
    justify-content: initial;
    max-width: 436px;
`}
  ${media.large`
    gap: ${spacing[4]};
`}
`;

const SearchButton = styled(Button)`
  height: 44px;
  line-height: 20px;
  border-radius: 5px;
  width: 100%;
  ${fontSizes[1]}
  ${media.medium`
    box-shadow: 0 8px 10px 0 rgba(51,51,51,0.1);
    max-width: 236px;
    width: 129px;
    margin-bottom: 0;
    &:hover {
      box-shadow: 0 8px 10px 0 rgba(51,51,51,0.1);
    }
  `};
  ${media.large`
    width: 141px;
  `}
`;

const { publicRuntimeConfig } = getConfig();

const DESTINATION_LABEL = "All Destinations";
const DATE_LABEL = "Any Date";
const DEPARTURE_PORTS_LABEL = "All Departure Ports";
const CRUISELINES_LABEL = "All Cruise Lines";

const LABELS = {
  destinations: DESTINATION_LABEL,
  departure_ports: DEPARTURE_PORTS_LABEL,
  months: DATE_LABEL,
  cruiselines: CRUISELINES_LABEL,
};

const HeroSearch = ({ filters }) => {
  const router = useRouter();

  const {
    cruiselines,
    departure_ports: departurePorts,
    destinations,
    months,
  } = filters;

  // Map initial data and add default labels
  const destinationOptions = [
    { value: "", label: DESTINATION_LABEL, default: true },
    ...destinations
      .filter((dest) => dest.popular)
      .map((destination) => ({
        label: destination.name,
        value: destination.id,
      })),
  ];

  const monthOptions = [
    { value: "", label: DATE_LABEL, default: true },
    ...months.map((month) => ({
      label: month.name,
      value: `${month.first_day}:${month.last_day}`, // store both dates as a range
    })),
  ];

  const departurePortOptions = [
    { value: "", label: DEPARTURE_PORTS_LABEL, default: true },
    ...departurePorts.map((port) => ({
      label: port.name,
      value: port.id,
    })),
  ];

  const cruiselineOptions = [
    { value: "", label: CRUISELINES_LABEL, default: true },
    ...cruiselines.map((cruiseline) => ({
      label: cruiseline.name,
      value: cruiseline.id,
    })),
  ];

  // Initial state setup
  const [selectedFilters, setSelectedFilters] = useState({});
  const [options, setOptions] = useState({
    destinations: destinationOptions,
    departure_ports: departurePortOptions,
    months: monthOptions,
    cruiselines: cruiselineOptions,
  });
  const [loading, setLoading] = useState(false);

  // Prefetch the search page for faster navigation
  useEffect(() => {
    router.prefetch("/search");
  }, [router]);

  useEffect(() => {
    const updateOptions = async () => {
      if (Object.keys(selectedFilters).length === 0) {
        return; // no selection to update yet so avoid API call
      }

      setLoading(true);
      try {
        const queryParams = new URLSearchParams(selectedFilters).toString();
        const response = await fetch(
          `${publicRuntimeConfig.POSEIDON_NEXT_API}/update_filters_data/?${queryParams}`,
          {
            method: "GET",
            headers: {
              "Content-Type": "application/json",
            },
            credentials: "include",
          }
        );

        if (!response.ok) {
          throw new Error("Network response was not ok");
        }

        const data = await response.json();

        setOptions((prev) => ({
          ...prev,
          destinations: [
            { value: "", label: DESTINATION_LABEL, default: true },
            ...(data.destinations
              ? data.destinations
                  .filter((dest) => dest.popular)
                  .map((dest) => ({
                    label: dest.name,
                    value: dest.id,
                    disabled: dest.disabled,
                  }))
              : []),
          ],
          departure_ports: [
            { value: "", label: DEPARTURE_PORTS_LABEL, default: true },
            ...(data.departure_ports
              ? data.departure_ports.map((port) => ({
                  label: `${port.name}`,
                  value: port.id,
                  disabled: port.disabled,
                }))
              : []),
          ],
          months: [
            { value: "", label: DATE_LABEL, default: true },
            ...(data.months
              ? data.months.map((month) => ({
                  label: month.name,
                  value: `${month.first_day}:${month.last_day}`,
                  disabled: month.disabled,
                }))
              : []),
          ],
          cruiselines: [
            { value: "", label: CRUISELINES_LABEL, default: true },
            ...(data.cruiselines
              ? data.cruiselines.map((line) => ({
                  label: line.name,
                  value: line.id,
                  disabled: line.disabled,
                }))
              : []),
          ],
        }));
      } catch (e) {
        // eslint-disable-next-line no-console
        console.error("Failed to fetch updated filter data: ", e.message);
      } finally {
        setLoading(false);
      }
    };

    updateOptions();
  }, [selectedFilters]);

  const constructSearchParams = (facets) => {
    return [
      facets.destinations &&
        `destination=${encodeURIComponent(facets.destinations)}`,
      facets.departure_ports &&
        `departure_port=${encodeURIComponent(facets.departure_ports)}`,
      facets.months &&
        (() => {
          const [start_date_after, start_date_before] =
            facets.months.split(":");
          return `start_date_after=${encodeURIComponent(
            start_date_after
          )}&start_date_before=${encodeURIComponent(start_date_before)}`;
        })(),
      facets.cruiselines &&
        `ship__cruiseline_id=${encodeURIComponent(facets.cruiselines)}`,
    ]
      .filter(Boolean) // ensure only non-false values are included
      .join("&"); // join parameters with '&' to form a complete query string
  };

  return (
    <Container>
      <SearchContainer aria-label="Search for cruises.">
        <FlexContainer display="contents">
          {[
            { name: "destinations", options: destinationOptions },
            { name: "departure_ports", options: departurePortOptions },
          ].map((filter) => (
            <SearchSelect
              id={`select-${filter.name}`}
              key={filter.name}
              options={options[filter.name]}
              value={selectedFilters[filter.name] || ""}
              disabled={loading}
              onChange={(newValue) => {
                setSelectedFilters((prev) => ({
                  ...prev,
                  [filter.name]: newValue,
                }));
              }}
              name={`${LABELS[filter.name]}.`}
              hideLabel
            />
          ))}
        </FlexContainer>
        <FlexContainer display="flex">
          {[
            { name: "months", options: monthOptions },
            { name: "cruiselines", options: cruiselineOptions },
          ].map((filter) => (
            <SearchSelect
              id={`select-${filter.name}`}
              key={filter.name}
              options={options[filter.name]}
              value={selectedFilters[filter.name] || ""}
              disabled={loading}
              onChange={(newValue) => {
                setSelectedFilters((prev) => ({
                  ...prev,
                  [filter.name]: newValue,
                }));
              }}
              name={`${LABELS[filter.name]}.`}
              hideLabel
            />
          ))}
        </FlexContainer>
        <SearchButton
          disabled={loading}
          onClick={() => {
            // Retrieve labels for tracking based on selected values
            const destinationName =
              options.destinations.find(
                (option) => option.value === selectedFilters.destinations
              )?.label || DESTINATION_LABEL;
            const departurePortName =
              options.departure_ports.find(
                (option) => option.value === selectedFilters.departure_ports
              )?.label || DEPARTURE_PORTS_LABEL;
            const monthName =
              options.months.find(
                (option) => option.value === selectedFilters.months
              )?.label || DATE_LABEL;
            const cruiselineName =
              options.cruiselines.find(
                (option) => option.value === selectedFilters.cruiselines
              )?.label || CRUISELINES_LABEL;

            // Construct the tracking label and track the search
            const trackingLabel = `${destinationName} | ${departurePortName} | ${monthName} | ${cruiselineName}`;
            trackSearchCruises(trackingLabel);

            // Generate the search parameters and transition the page
            const params = constructSearchParams(selectedFilters);
            router.push(`/search?${params}`);
          }}
        >
          Search Cruises
        </SearchButton>
      </SearchContainer>
    </Container>
  );
};

HeroSearch.propTypes = {
  filters: FILTER_PROPS_FROM_API,
};

export default HeroSearch;
